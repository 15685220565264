import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Materiály" description="@todo" mdxType="SEO" />
    <h1 {...{
      "id": "materiály"
    }}>{`Materiály`}</h1>
    <p>{`V našich výrobcích najdete tyto materiály:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/technologie/materialy/polyethylen-pe/"
        }}>{`Polyethylen (PE)`}</a>{` (také známý jako `}<a parentName="li" {...{
          "href": "/technologie/materialy/igelit/"
        }}>{`igelit`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/technologie/materialy/polypropylen-pp/"
        }}>{`Polypropylen (PP)`}</a></li>
    </ul>
    <h2 {...{
      "id": "certifikace"
    }}>{`Certifikace`}</h2>
    <p>{`Naše výrobky také splňují následující atestace:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/technologie/materialy/atest-pro-styk-s-potravinami/"
        }}>{`Atest pro styk s potravinami`}</a></li>
    </ul>
    <p><strong parentName="p">{`todo`}</strong>{` - text a ukázka`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      